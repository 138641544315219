import React from 'react'
import './App.css'

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'
import {
    CssBaseline, createStyles, Theme, makeStyles, Paper,
    Grid, Card, CardHeader, CardContent, CardActions, IconButton,
    Typography, Tooltip
} from '@material-ui/core'

import LinkedInIcon from '@material-ui/icons/LinkedIn'
import GitHubIcon from '@material-ui/icons/GitHub'
import TwitterIcon from '@material-ui/icons/Twitter'
import EmailIcon from '@material-ui/icons/Email'
import LanguageIcon from '@material-ui/icons/Language'

import atlanta from './media/atlanta-filtered.jpg'
import profile from './media/profile.png'

import { ReactComponent as Flutter } from './media/flutter.svg'
import { SvgIconComponent } from '@material-ui/icons'

import Pll from 'react-pll'

const theme = createTheme({
    palette: {
        primary: {
            main: '#175676'
        },
        secondary: {
            main: '#D62839'
        },
        background: {
            default: '#EEEEEE'
        }
    }
})

const useStyles = makeStyles((theme: Theme) => createStyles({
    header: {
        backgroundImage: `url(${atlanta})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '50vh',
        width: '100%'
    },
    actions: {
        backgroundColor: '#175676',
        color: '#EEEEEE',
    },
    profile: {
        width: '100%',
        height: 'auto',
        margin: 'auto',
        maxWidth: '350px'
    },
    lang: {
        width: '100%',
        heigh: '100%',
    },
    project: {
        width: '100%',
    },
    socialIcon: {
        color: '#EEEEEE',
    },
    headerBox: {
        height: '5vh',
        [theme.breakpoints.up('sm')]: {
            height: '25vh'
        },
    }
}))

interface SocialIcon {
    name: string
    url: string
    icon: SvgIconComponent
}

interface Project {
    name: string
    title: string
    url: string
    desc: string
    languages: string[]
}

const projects: Project[] = [
    {
        name: 'ProtocolLib',
        title: 'Lead Maintainer',
        url: 'https://github.com/dmulloy2/ProtocolLib',
        desc: 'Bukkit Packet interception library with over 3 million downloads. Hooks into Netty to allow plugin developers to create highly custom behavior. Supports a large ecosystem of developers and plugins using the API.',
        languages: ['java']
    },
    {
        name: 'ClerkMedic',
        title: 'Technical Lead',
        url: 'https://clerkmedic.com',
        desc: 'Worked on a small startup team to develop a full-stack web application utilizing OCR to aid in the processing of medical faxes. Built primarily in React and Django and using the tesseract library. Hosted in AWS and used EC2 and Lambdas.',
        languages: ['python']
    },
    {
        name: 'Insights Counseling',
        title: 'Web Designer',
        url: 'https://insights-counseling.com',
        desc: 'Created and designed website for local therapy practice. Built in React using Gatsby and Bootstrap. Worked closely with customer for requirements.',
        languages: ['typescript']
    },
    {
        name: 'Chewse',
        title: 'Developer',
        url: 'https://testflight.apple.com/join/lj8xmuv4',
        desc: 'Developing Flutter app with small team to help groups decide on where to eat. Supports both Android and iOS and uses a Firebase backend. Currently available on TestFlight.',
        languages: ['flutter']
    },
    {
        name: 'C1 Games Terminal Competition',
        title: 'Participant',
        url: 'https://terminal.c1games.com/home',
        desc: 'Developed a genetic algorithm to generate and improve neural networks for competing in Terminal. Used Keras and Tesseract. Neural nets were translated into game agents that competed.',
        languages: ['python']
    },
    {
        name: 'Other Bukkit Plugins',
        title: 'Developer',
        url: 'https://github.com/dmulloy2?tab=repositories&q=bukkit',
        desc: 'Wrote and maintained 30 public Bukkit plugins in association with MineSworn. Highlights include UltimateArena, SwornRPG, AutoCraft, and ShadowPerms. Shared code using SwornAPI.',
        languages: ['java']
    },
]

const social: SocialIcon[] = [
    {
        name: 'LinkedIn',
        url: 'https://linkedin.com/in/dan-mulloy',
        icon: LinkedInIcon,
    },
    {
        name: 'GitHub',
        url: 'https://github.com/dmulloy2',
        icon: GitHubIcon,
    },
    {
        name: 'Twitter',
        url: 'https://twitter.com/dmulloy2',
        icon: TwitterIcon,   
    },
    {
        name: 'Email',
        url: 'mailto:jobs@dmulloy2.net',
        icon: EmailIcon,
    },
]

const langs: Record<string, string> = {
    'java': 'Java',
    'cpp': 'C/C++',
    'python': 'Python',
    'typescript': 'TypeScript',
    'flutter': 'Flutter',
    'csharp': 'C#'
}

function getIcon(lang: string, size: number) {
    return (
        <Tooltip title={langs[lang]}>
            <span>
            <IconButton disabled>
            {lang === 'flutter'
                ? <Flutter style={{ height: `${size}px`, width: `${size}px` }}/>
                : <Pll language={lang} height={size} />
            }
            </IconButton>
            </span>
        </Tooltip>
    )
}

function App() {
    const styles =  useStyles()

    return (
        <MuiThemeProvider theme={theme}>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
            <CssBaseline />

            <Paper className={styles.header}>
                <Grid container direction="row" alignItems="center" justify="center">
                    <Grid container item xs={12} sm={10} md={8} spacing={4} direction="column" alignItems="center" justify="center">
                        <Grid item>
                            <div className={styles.headerBox} />
                            <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        spacing={2}
                                        justify="flex-start"
                                        alignItems="flex-start"
                                    >
                                        <Grid item xs={12} sm={4}>
                                            <img src={profile} className={styles.profile} alt="Me" />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            sm={8}
                                            container
                                            justify="flex-start"
                                            alignItems="flex-start"
                                            spacing={1}
                                            direction="column"
                                        >
                                            <Grid item>
                                                <Typography variant="h3">
                                                    Dan Mulloy
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h6">
                                                    Software Engineer
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    Hey, I'm Dan! I'm a full-stack software developer at Microsoft. In my spare time, I also maintain some open source projects on my GitHub. Feel free to connect with me on LinkedIn or send me an email below!
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions className={styles.actions}>
                                    <Grid container alignItems="center" justify="center" spacing={2}>
                                        {social.map((data: SocialIcon) => (
                                            <Grid item key={data.name}>
                                                <Tooltip title={data.name}>
                                                    <IconButton
                                                        target="_blank"
                                                        href={data.url}
                                                    >
                                                        <data.icon className={styles.socialIcon} />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ textAlign: 'center' }}>
                                Technologies
                            </Typography>
                            <br/>
                            <Card>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        {['python', 'java', 'typescript', 'flutter', 'cpp', 'csharp'].map((lang: string) => (
                                            <Grid xs={4} sm item key={`icon-tech-${lang}`} style={{ textAlign: 'center' }}>
                                                {getIcon(lang, 48)}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </CardContent>
                            </Card>
                            
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" style={{ textAlign: 'center' }}>
                                Projects
                            </Typography>
                            <br/>
                            <Grid
                                container
                                direction="row"
                                justify="center"
                                alignItems="stretch"
                                spacing={3}
                            >
                                {projects.map((project: Project) => (
                                    <Grid
                                        item
                                        xs={12} md={4}
                                        style={{ display: 'flex' }}
                                        key={project.name}
                                    >
                                        <Card className={styles.project}>
                                            <CardHeader
                                                title={project.name}
                                                subheader={project.title}
                                            />
                                            <CardContent>{project.desc}</CardContent>
                                            <CardActions>
                                                {project.url &&
                                                <Tooltip title="Project Site">
                                                    <IconButton
                                                        href={project.url}
                                                        target="_blank"
                                                    >
                                                        <LanguageIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                }
                                                {project.languages.map((lang: string) => (
                                                    <Grid item xs key={`icon-project-${project.name}-${lang}`}>
                                                        {getIcon(lang, 24)}
                                                    </Grid>
                                                ))
                                                }
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </MuiThemeProvider>
    )
}

export default App
